import React from 'react'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from "gatsby-plugin-image"

import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Breadcrumbs from '../components/Breadcrumbs'


const VhwAbout = ({ data, pageContext, location }) => {
  const vhwabout = data.strapiVhwAbout
  const { previous, next } = pageContext

  return (
    <Layout pageData={data.strapiAbout}>
      <Seo
        title={vhwabout.name}
        description={vhwabout.description}
      />
      <Breadcrumbs
        firstName="Over ons"
        firstSlug="over-ons"
        secondName={vhwabout.name}
      />

      <section className="section">
        <div className="container content">
          <div className="columns">
            <div className="column">
              <GatsbyImage
                image={vhwabout.image.localFile.childImageSharp.gatsbyImageData}
                alt={vhwabout.name}
              />
            </div>
            <div className="column">
              <h1 className="title">{vhwabout.name}</h1>
              <ReactMarkdown
                className="line-break"
                children={vhwabout.description} 
                remarkPlugins={[remarkGfm]}
              />
            </div>
          </div>
        </div>
      </section>

      <nav
        className="pagination is-centered"
        role="navigation"
        aria-label="pagination"
      >
        {previous && (
          <Link to={`/over-ons/${previous.slug}`} className="pagination-previous">
            {previous.name}
          </Link>
        )}
        {next && (
          <Link to={`/over-ons/${next.slug}`} className="pagination-next">
            {next.name}
          </Link>
        )}
      </nav>
    </Layout>
  )
}

export default VhwAbout


export const query = graphql `
  query VhwAboutQuery($id: String!) {
    strapiAbout {
      title
      subtitle
      cover {
        localFile {
          childImageSharp {
            gatsbyImageData(
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
    strapiVhwAbout(id: {eq: $id}) {
      strapiId
      name
      slug
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(
              # width: 1600
              transformOptions: {grayscale: true}
            )
          }
        }
      }
    }
  }
`
